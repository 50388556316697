import React from "react"
import { Text } from "theme-ui"
import Layout from "../../components/layout"
import RoleTemplate from "../../components/roles/RoleTemplate"
import Pagination from "../../components/Pagination"

import portraitImage from "../../images/role-portraits/wizard-cutout.png"
import wizard01Image from "../../images/ability-cards/wizard-03.png"
import wizard02Image from "../../images/ability-cards/wizard-02.png"
import wizard03Image from "../../images/ability-cards/wizard-01.png"
import wizard04Image from "../../images/ability-cards/wizard-04.png"

import YAMLData from "../../data/learning-paths/wizard.yaml"

const WizardPage = () => (
  <Layout>
    <RoleTemplate
      name="wizard"
      src={portraitImage}
      bgGradient="to bottom, #79634B, #987C4D"
      introDescription={
        <>
          <Text>The Wizard utters a word of power, sending a wave of force that knocks back everything in its path. They imagine an object needed in a pinch, and conjure it from thin air. With a wave of their hand, they open a rift to unexplored dimensions, seeking answers beyond space and time.</Text>
          <Text>You can use The Wizard’s generalist magic role to play any kind of spellcaster you want, from a bookish academic on a knowledge quest, to a conjurer of tricks, or a megalomaniacal mage seeking absolute power.</Text>
        </>
      }
      imgWidth={700}
      imgLeftPos="auto"
      imgRightPos={[2, 4, 6]}
      abilityDescription="The Wizard is the most versatile magic user in Quest, capable of both minor tricks and incredible world-shaking magic feats. You can use this role to build almost any kind of mage you want, from a wizard of classic lore who uses their power in subtle ways, to a flashy spellcaster who flaunts their skills."
      abilityImg01={wizard01Image}
      abilityImg02={wizard02Image}
      abilityImg03={wizard03Image}
      abilityImg04={wizard04Image}
      yaml={YAMLData}
    />

    <Pagination parent="roles" prev="magician" next="fighter" />
  </Layout>
)

export default WizardPage
